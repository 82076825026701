import React from "react";
import HubspotForm from "react-hubspot-form";

const ContactForm = () => (
    <div className="contact-form">
        <div className="text-start mb-4">
            <h2></h2>
            <p>
                Your inquiries are welcome. Send us a quick message and
                we&apos;ll get back to you real soon.
            </p>
        </div>
        <HubspotForm
            portalId="6139600"
            formId="a9531cf3-9215-4ec4-b97f-c811224a00e3"
            loading={<div>Loading...</div>}
        />
    </div>
);

export default ContactForm;
