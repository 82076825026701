import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import * as style from "../../styles/post-card.module.scss";

import arrowIcon from "../../images/icons/long-arrow-alt-right-solid.svg";

// eslint-disable-next-line camelcase
const PostCard = ({ post, featureImage }) => {
    const url = post.fields.path;

    const backgroundStyles = {
        backgroundImage: `url(${featureImage || post.feature_image})`,
        backgroudRepeat: `no-repeat`,
        backgroundPosition: `center`,
        backgroundSize: `cover`,
        height: `100%`,
    };

    return (
        <div className={`${style.clarsyntPostCard}`}>
            <Link to={url}>
                <div style={backgroundStyles}>
                    <div className={`${style.clarsyntPostCardContent}`}>
                        <h2>{post.title}</h2>
                        <p>{post.excerpt}</p>
                        <div>
                            Read More
                            <span>
                                <img src={arrowIcon} alt={`an arrow icon`} />
                            </span>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
};

PostCard.propTypes = {
    post: PropTypes.shape({
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        feature_image: PropTypes.string,
        featured: PropTypes.bool,
        fields: PropTypes.shape({
            path: PropTypes.string,
        }),
        tags: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            })
        ),
        excerpt: PropTypes.string.isRequired,
        primary_author: PropTypes.shape({
            name: PropTypes.string.isRequired,
            profile_image: PropTypes.string,
        }).isRequired,
    }).isRequired,
    featureImage: PropTypes.string,
};

export default PostCard;
