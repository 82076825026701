import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Layout } from "../components/common";
import { Link } from "gatsby";
import * as style from "../styles/case-study.module.scss";
import { Button } from "react-bootstrap";
import ContactForm from "../components/ContactForm";
import WebsiteMeta from "../components/common/meta/WebsiteMeta";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

const CaseStudy = ({ data }) => {
    const {
        title,
        feature_image,
        introduction,
        introduction_image,
        introduction_image_alt,
        goal,
        solution,
        company_profile,
        company_profile_image,
        company_profile_image_alt,
        quote,
        quote_author,
        key_findings,
        fact,
        result_title,
        result_text,
        result_icon,
        result_icon_alt,
        case_file,
        meta_title,
        meta_description,
        canonical_url,
        tags,
    } = data.markdownRemark.frontmatter;

    return (
        <>
            <WebsiteMeta
                title={meta_title}
                description={meta_description}
                canonical={canonical_url || null}
                image={feature_image.publicURL}
            />

            <Layout
                post
                headerImage={feature_image && feature_image.publicURL}
                headerText={<div className={`pt-5`}>{title}</div>}
            >
                <div className={`bg-color-offwhite `}>
                    <div className={`container py-5`}>
                        <Link to={`/insights/${tags[0].slug}`}>
                            <div className={`clarsynt-tag`}>
                                <span>{tags[0].name}</span>
                            </div>
                        </Link>
                        <div
                            className={`pt-5 d-flex flex-column-reverse flex-lg-row align-items-start gap-5`}
                        >
                            <div>
                                {introduction && (
                                    <p
                                        className={`${style.caseStudyIntroduction}`}
                                    >
                                        {introduction}
                                    </p>
                                )}

                                {goal && (
                                    <>
                                        <h3>Goal:</h3>
                                        <p>{goal}</p>
                                    </>
                                )}
                                {solution && (
                                    <>
                                        <h3>Solution:</h3>
                                        <p>{solution}</p>
                                    </>
                                )}
                            </div>
                            {introduction_image && (
                                <div
                                    className={`${style.caseStudyIntroductionImage}`}
                                >
                                    <GatsbyImage
                                        image={
                                            introduction_image.childImageSharp
                                                .gatsbyImageData
                                        }
                                        alt={introduction_image_alt}
                                    />
                                </div>
                            )}
                        </div>
                        {case_file && (
                            <Button
                                variant="info"
                                size={`lg`}
                                href={case_file.publicURL}
                                target="_blank"
                                className={`px-4 mt-4 text-light`}
                            >
                                Read the Full Case Study
                            </Button>
                        )}
                    </div>
                </div>
                {company_profile && (
                    <div className={`${style.caseStudyCompanyProfile}`}>
                        <div
                            className={`container d-flex flex-column flex-lg-row align-items-start align-items-lg-center gap-3`}
                        >
                            {company_profile_image && (
                                <div
                                    className={`${style.caseStudyCompanyProfileImage}`}
                                >
                                    <GatsbyImage
                                        image={
                                            company_profile_image
                                                .childImageSharp.gatsbyImageData
                                        }
                                        alt={company_profile_image_alt}
                                    />
                                </div>
                            )}
                            <div className={`px-lg-5 pt-4 pt-lg-0`}>
                                <h2>Company Profile</h2>
                                <p>{company_profile}</p>
                            </div>
                        </div>
                    </div>
                )}

                {quote && (
                    <div className={`${style.caseStudyQuote}`}>
                        <div
                            className={`container d-flex flex-column flex-lg-row align-items-lg-center align-items-start gap-5`}
                        >
                            <StaticImage
                                src="../images/icons/quote.svg"
                                width={221}
                                alt="quote icon"
                            />
                            <div className={`px-4`}>
                                <p>{quote}</p>
                                {quote_author && <h5>—{quote_author}</h5>}
                            </div>
                        </div>
                    </div>
                )}

                <div className={`bg-color-offwhite pt-5`}>
                    <div className={`container pt-5`}>
                        <div className={`d-flex flex-column flex-lg-row gap-5`}>
                            {key_findings && (
                                <div className={`me-lg-5`}>
                                    <h3>Key Findings</h3>
                                    <p>
                                        {key_findings
                                            .split(`\n`)
                                            .map((line, i) => (
                                                <>
                                                    {line !== `\n` && (
                                                        <span>
                                                            {line}
                                                            <br />
                                                        </span>
                                                    )}
                                                </>
                                            ))}
                                    </p>
                                </div>
                            )}
                            <div
                                className={`d-flex flex-column align-items-center gap-5`}
                            >
                                {fact && (
                                    <div className={`${style.caseStudyFact}`}>
                                        <span>Fact:</span>
                                        <p>{fact}</p>
                                    </div>
                                )}
                                {case_file && (
                                    <Button
                                        variant="info"
                                        size={`lg`}
                                        href={case_file.publicURL}
                                        target="_blank"
                                        className={`px-4 text-light`}
                                    >
                                        Download the
                                        <br />
                                        Case Study
                                    </Button>
                                )}
                            </div>
                        </div>
                        {result_text && (
                            <div className={`${style.caseStudyResult} mb-5`}>
                                <span>
                                    <GatsbyImage
                                        image={
                                            result_icon.childImageSharp
                                                .gatsbyImageData
                                        }
                                        alt={result_icon_alt}
                                    />
                                </span>
                                <h3>{result_title}</h3>
                                <p>{result_text}</p>
                            </div>
                        )}

                        <div className={`pt-5`}>
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </Layout>
        </>
    );
};

CaseStudy.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.shape({
                title: PropTypes.string,
                slug: PropTypes.string,
                feature_image: PropTypes.string,
                published_at: PropTypes.string,
                introduction: PropTypes.string,
                introduction_image: PropTypes.object,
                introduction_image_alt: PropTypes.string,
                goal: PropTypes.string,
                solution: PropTypes.string,
                company_profile: PropTypes.string,
                company_profile_image: PropTypes.object,
                company_profile_image_alt: PropTypes.string,
                quote: PropTypes.string,
                quote_author: PropTypes.string,
                key_findings: PropTypes.string,
                fact: PropTypes.string,
                result_title: PropTypes.string,
                result_text: PropTypes.string,
                result_icon: PropTypes.object,
                result_icon_alt: PropTypes.string,
                case_file: PropTypes.string,
                meta_title: PropTypes.string,
                meta_description: PropTypes.string,
                canonical_url: PropTypes.string,
                tags: PropTypes.arrayOf(
                    PropTypes.shape({
                        name: PropTypes.string,
                        slug: PropTypes.string,
                    })
                ),
            }),
        }),
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default CaseStudy;

export const query = graphql`
    query CaseStudy($slug: String) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            html
            frontmatter {
                slug
                title
                quote
                result_title
                result_text
                result_icon_alt
                result_icon {
                    childImageSharp {
                        gatsbyImageData(width: 65)
                    }
                }
                key_findings
                introduction_image_alt
                introduction_image {
                    childImageSharp {
                        gatsbyImageData(width: 365)
                    }
                }
                company_profile
                company_profile_image_alt
                company_profile_image {
                    childImageSharp {
                        gatsbyImageData(width: 192)
                    }
                }
                case_file {
                    publicURL
                }
                published_at_pretty: published_at(formatString: "DD MMMM, YYYY")
                feature_image {
                    publicURL
                }
                fact
                goal
                introduction
                quote_author
                solution
                meta_title
                meta_description
                canonical_url
                tags {
                    name
                    slug
                }
            }
        }
    }
`;
